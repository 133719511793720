import React, { useState, useEffect, useRef } from 'react'
import { Pagination, Spin, Modal, message } from 'antd'
import { useLocation, useHistory, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../store'

import { NoRecord } from '../../../components'
import { AddMaterialModal } from '../components/add-material'
import { SelectOrderType } from '../components/select-order-type'

import util from '../../../libs/util'
import { operatorTypeItems, deliveryCinemaModelItems } from '../../../libs/util.constants'

import styles from './AdList.module.css'
import { MailOutlined } from '@ant-design/icons'

interface Props {
  location: {
    search?: any,
  },
}

export const AdExecList: React.FC<Props> = (props) => {
	const userType = useSelector((state: RootState) => {
    return state.user.myUserInfo ? state.user.myUserInfo.userType : ''
  })
  const isManager = useSelector((state: RootState) => {
    return state.user.userRoleList ? state.user.userRoleList.find(i => i.roleItemKey === 'MEDIA_OPERATION') : false
  })
  const checkPayAuth = () => {
    // 个人用户中，只有管理员能支付
    const hasAuth = userType !== 'COMPANY' && isManager || userType === 'COMPANY'
    if (!hasAuth) {
      Modal.warning({
        title: '暂无支付权限',
        content: `请联系企业或管理员进行操作`,
        okText: '我知道了',
        closable: false,
        onOk() {
        },
      })
    }
    return hasAuth
  }

	const tabList = [
		{
			id: 'PAY_WAIT,DELIVERY_WAIT,EXECUTING,FINISHED,NO,CANCEL,CONTENT_TIMEOUT,TIMEOUT_P,PAY_TIMEOUT,CANCEL_PAY',
			name: '全部',
			planAims: ['NORMAL', 'SELF', 'EXCLUSIVE_MEDIA', 'KA'],
		},
		{
			id: 'PAY_WAIT',
			name: '待付款',
			planAims: ['SELF', 'EXCLUSIVE_MEDIA'],
		},
		{
			id: 'DELIVERY_WAIT',
			name: '待上刊',
			planAims: ['NORMAL', 'SELF', 'EXCLUSIVE_MEDIA', 'KA'],
		},
		{
			id: 'EXECUTING',
			name: '刊播中',
			planAims: ['NORMAL', 'SELF', 'EXCLUSIVE_MEDIA', 'KA'],
		},
		{
			id: 'FINISHED',
			name: '已完成',
			planAims: ['NORMAL', 'SELF', 'EXCLUSIVE_MEDIA', 'KA'],
		},
	]
	
	useEffect(() => {
		getOwnerPointSummary()
		const search = props.location.search
		if (!search) {
			getList(1)
			return
		}
		const status = search?.split('?')[1].split('=')[1]
		const id = tabList.find(i => i.id === status)?.id
		setCurTabId(id)
		getList(1, id)
	}, [])

	const [curTabId, setCurTabId] = useState<any>(tabList[0].id)

	const baseURL = `https://${ util.tools.env }cdn.remudooh.com/`

	const [list, setList] = useState<any[]>([])
  const [defaultCurrent, setDefaultCurrentTotal] = useState(1)
  const [pageNum, setPageNum] = useState(1)
  const [pageSizeOptions, setPageSizeOptions] = useState(['10', '20', '30'])
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  useEffect(() => {
    setPageNum(defaultCurrent)
  }, [pageSize])

  const [isSubmiting, setIsSubmiting] = useState(false)
	// 获取列表
  const getList = async (num?: number, id?: string) => {
    if (num) {
      setPageNum(num)
    }
    setIsSubmiting(true)
    let params = {
      orderByCreateDate: 'DESC',
      planAims: tabList.find(i => i.id === (id || curTabId))?.planAims,
      searchModel: 1,
      approveStatus: (id || curTabId).split(','),
    }
    const { data = [], total = 0, code } = await util.request({
      method: 'POST',
      url: `mall/media/order/searchOrderMediaList?pageNum=${num || pageNum}&pageSize=${pageSize}`,
      data: params,
    })
    if (code === 1) {
	    setList(data)
	    setTotal(total)
	    await getOrderTotal(data)
	    setIsSubmiting(false)
	  }
  }
  const getOrderTotal = async (list) => {
  	if (!list.length) {
      return
    }
  	const { data = [], total = 0, code } = await util.request({
      method: 'POST',
      url: `/mall/order/getOrderTotal`,
      data: {
      	mallOrderPlanIds: list.map(i => i.mallOrderPlanId),
      },
    })

  	if (code === 1) {
	    list.forEach((item, index) => {
	      const orderTotal = data.find(i => i.mallOrderPlanId === item.mallOrderPlanId)
	      item.orderTotal = orderTotal || null
	    })

	    setList(list)
	  }
  }
  // 页码变化
  const onPageChange = (page, size) => {
    if (pageSize !== size) {
      return
    }
    setPageNum(page)
    getList(page)
    console.log('onPageChange', page)
  }
  const onShowSizeChange = (current, size) => {
    setPageSize(size)
  }

  const toggleTab = (id) => {
  	if (id === curTabId) {
  		return
  	}
  	setCurTabId(id)
  	setPageNum(1)
  	getList(1, id)
  	console.log('toggleTab', id, curTabId)
  }

  const history = useHistory()
	const [summary, setSummary] = useState<any>()
  const getOwnerPointSummary = async () => {
    const result = await util.request({
      method: 'POST',
      url: 'resource/getOwnerPointSummary',
    })

    if (result.code === 1) {
      setSummary(result.data.notActivateCinemaCount)
    }
  }
	const selectOrderTypeModalRef: any = useRef()
  const showModal = () => {
    selectOrderTypeModalRef.current.onToggle()
  }
	const onCreate = (curType) => {
		if (summary > 0) {
			Modal.confirm({
				title: `${summary}个点位未激活。继续投放，只可投放已激活的点位`,
				cancelText: '取消',
				okText: '继续投放',
				onOk() {
					toCreatePage(curType)
				},
				onCancel() {
				},
			})
      return
    }
		toCreatePage(curType)
	}
	const toCreatePage = (curType) => {
		history.push({ 
			pathname: `/ad-exec/create/${ curType.id }/${ curType.planAimExt }`,
		})
	}

  const goToDetailPage = (data) => {
  	// 自用、独家的用原广告主的
  	const url = (data.planAim === 'SELF' || data.planAim === 'EXCLUSIVE_MEDIA') ? `/ad-exec/media-detail/${ data.mallOrderPlanId }` : `/ad-exec/plan-detail/${ data.mallOrderMediaId }`
  	history.push(url)
  }
  
  const addMaterialModalRef: any = useRef()
  const onMaterial = (ad) => {
    addMaterialModalRef.current.onToggle({
      isFormAd: true,
      deliveryContentDuration: ad.deliveryContentDuration,
      deliveryIndustry: ad.deliveryIndustry,
      mallOrderPlanId: ad.mallOrderPlanId,
    })
  }

  const onPay = (record) => {
		if (!checkPayAuth()) {
			return
		}
  	history.push(`/ad-exec/cashier/${ record.mallOrderPlanId }/${ false }`)
  }

  const getLeftBanner = (ad) => {
		/* 平台未及时审核已提交素材 */
		if (ad.approveStatus === 'TIMEOUT_P' && ad.showPlanStatusMore && ad.showPlanStatusMore?.includes('APPROVE_PLATFORM_TIMEOUT')) {
			return (<div className={ `${ styles['ad-material-tips'] }` } >平台超时未审核</div>)
		}

		/* 审核中 */
		else if (ad.approveStatus !== 'EXECUTING' && ad.approveStatus !== 'FINISHED' && ad.showPlanStatusMore && ad.showPlanStatusMore?.includes('CONTENT_WAIT')) {
			return (<div className={ `${ styles['ad-material-tips'] }` } >审核中</div>)
		}

		/* 不符合投放要求 */
		else if ((ad.planAim === 'SELF' || ad.planAim === 'EXCLUSIVE_MEDIA') && ad.approveStatus !== 'EXECUTING' && ad.approveStatus !== 'FINISHED' && ad.showPlanStatusMore && (ad.showPlanStatusMore?.includes('CONTENT_NO') || ad.showPlanStatusMore?.includes('CONTENT_NOT_MATCH'))) {
			return (<div className={ `${ styles['ad-material-tips'] }` } >不符合投放要求</div>)
		}

		/* 素材已删除 */
		else if (ad.showPlanStatusMore && ad.showPlanStatusMore?.includes('CONTENT_DELETE')) {
			return (<div className={ `${ styles['ad-material-tips'] }` } >素材已删除</div>)
		}

		/* 未提交素材 ad.approveStatus !== 'EXECUTING' && ad.approveStatus !== 'FINISHED' &&  */
  	else if (ad.showPlanStatusMore && (ad.showPlanStatusMore?.includes('CONTENT_NULL') || ad.showPlanStatusMore?.includes('CONTENT_DELETE') || !ad.thumbnailUrl)) {
			return (<div className={ `icon ${ styles['ad-material-default'] }` }></div>)
		}
  }

	const onRemove = (record) => {
		Modal.confirm({
			title: '确认要删除订单？',
			cancelText: '取消',
			okText: '确定',
			onOk() {
				goToRemove(record.mallOrderMediaId)
			},
			onCancel() {
			},
		})
	}
	// 去删除
  const goToRemove = async (id) => {
		setIsSubmiting(true)
		const { data = [], message: msg, code } = await util.request({
      method: 'DELETE',
      url: `/mall/media/order/removeOrderMedia/${ id }`,
    })

  	if (code === 1) {
      message.success('删除成功')
			getList(1)
    } else {
      message.error(msg || '操作失败')
    }
    setIsSubmiting(false)
  }

	const onReport = (ad) => {
  	history.push(`/ad-exec/report/home/${ ad.mallOrderMediaId }`)
  }

  return (
    <div className={ `${ styles['ad-page'] }` }>
      <div className={ `${ styles['ad-page-tit'] }` }>
        <h3>广告执行单</h3>
      </div>

      <div className={ `${ styles['ad-cont'] }` }>
      	<div className={ `${ styles['ad-cont-tit'] }` }>广告执行单</div>

      	<div className={ `${ styles['ad-list-top'] }` }>
	      	{/*tab*/}
	      	<div className={ `${ styles['tab-list'] }` }>
		      	{
		      		tabList.map((tab) => {
		      			return (
		      				<div className={ `${ styles['tab-item'] } ${ curTabId === tab.id ? styles['is-selected'] : '' }` } key={ tab.id } onClick={ () => { toggleTab(tab.id) } }>{ tab.name }</div>
		      			)
		      		})
		      	}
	      	</div>

	      	<div className={ `${ styles['btn-add-adexec'] }` } onClick={ showModal }><div className={ `${ styles['btn-add-icon'] } imediafont i-plus-circle` }></div>创建广告执行单</div>
      	</div>

      	{/*list*/}
      	<Spin spinning={ isSubmiting } tip="请稍候...">
      		{
      			(list.length > 0) && (
      				<div className={ `${ styles['ad-list'] }` }>
	      				{
	      					list.map((ad) => {
	      						return (
	      							<div className={ `${ styles['ad-item'] }` } key={ ad.mallOrderPlanId }>
	      								<div className={ `${ styles['ad-labels'] }` }>
	      									<div className={ `${ styles['ad-label'] }` }>{ util.tools.getNameFromKey(operatorTypeItems, ad.operatorType) }
	      										{
	      											util.tools.convertAdExecChannelName(ad) && (<span className={ `${ styles['channel-label'] }` }>{ util.tools.convertAdExecChannelName(ad) }</span>)
	      										}
	      									</div>
	      									<div className={ `${ styles['ad-label'] }` }>{ util.tools.getNameFromKey(deliveryCinemaModelItems, ad.deliveryCinemaModel) }</div>
	      								</div>

	      								<div className={ `${ styles['ad-status'] }` }>{ util.tools.adExecStatus(ad.approveStatus).text }</div>

	      								
	      								<div className={ `${ styles['ad-item-cont'] }` } onClick={ () => { goToDetailPage(ad) } }>
	      									{ 
	      										(ad.approveStatus === 'DELIVERY_WAIT' || ad.approveStatus === 'EXECUTING' || ad.approveStatus === 'FINISHED' || (ad.approveStatus === 'CONTENT_TIMEOUT' && ad.showPlanStatusMore && !ad.showPlanStatusMore?.includes('PAY_TIMEOUT') && !ad.showPlanStatusMore?.includes('CANCEL_PAY') && (ad.showPlanStatusMore?.includes('APPROVE_PLATFORM_TIMEOUT') || ad.showPlanStatusMore?.includes('CONTENT_NULL') || ad.showPlanStatusMore?.includes('CONTENT_WAIT') || ad.showPlanStatusMore?.includes('CONTENT_NO') || ad.showPlanStatusMore?.includes('APPROVE_NO'))) || ad.approveStatus === 'TIMEOUT_P') && (
	      												<div className={ `${ styles['ad-material'] }` }>
	      													{
	      														(ad.thumbnailUrl) && (<img className={ `${ styles['ad-material-image'] }` } src={ `${ baseURL }${ ad.thumbnailUrl[0] }?imageMogr2/thumbnail/x75` } />)
	      													}
	      													{
	      														getLeftBanner(ad)
	      													}
  															</div>
	      											)
	      									}
	      									{/*广告图*/}
													<div className={ `${ styles['ad-item-r'] }` }>
			      								<div className={ `${ styles['delivery-name'] }` }>{ ad.mediaDeliveryName }</div>

			      								{/*订单详细数据*/}
														<div className={ `${ styles['ad-form'] }` }>
															<div className={ `${ styles['ad-form-item'] }` }>
																<div className={ `${ styles['ad-form-label'] }` }>
																	<div className={ `${ styles['ad-form-icon'] } icon icon-calendar` }></div>
																	投放周期：
																</div>
																<div className={ `${ styles['ad-form-value'] }` }>
																	{ util.tools.formatDateStr(ad.mediaDeliveryStart) }-{ util.tools.formatDateStr(ad.mediaDeliveryEnd) }<span className={ `${ styles['text-days'] }` }>({ ad.deliveryDays }天)</span>
																</div>
															</div>
															<div className={ `${ styles['ad-form-item'] }` }>
																<div className={ `${ styles['ad-form-label'] }` }>
																	<div className={ `${ styles['ad-form-icon'] } icon icon-clock` }></div>
																	广告时长：
																</div>
																<div className={ `${ styles['ad-form-value'] }` }>{ ad.deliveryContentDuration }秒
																	{
																		(ad.planAim === 'EXCLUSIVE_AD' || ad.planAim === 'EXCLUSIVE_MEDIA' || ad.planAim === 'SELF') && '以内'
																	}
																</div>
															</div>
															<div className={ `${ styles['ad-form-item'] }` }>
																<div className={ `${ styles['ad-form-label'] }` }>
																	<div className={ `${ styles['ad-form-icon'] } icon icon-order` }></div>
																	播放时段：
																</div>
																<div className={ `${ styles['ad-form-value'] }` }>
																	{ util.tools.convertTimePeriods(ad)}
																</div>
															</div>
															<div className={ `${ styles['ad-form-item'] }` }>
																<div className={ `${ styles['ad-form-label'] }` }>
																	<div className={ `${ styles['ad-form-icon'] } icon icon-cell` }></div>
																	投放点位：
																</div>
																<div className={ `${ styles['ad-form-value'] }` }>
																	{ (ad.orderTotal?.pointUsableCount + ad.orderTotal?.disablePointCount) || 0 }个点位
																	{
																		(ad.orderTotal?.denyPointCount > 0) && <span className={ `${ styles['ad-form-value-tips'] }` }>{ ad.orderTotal?.denyPointCount }个谢绝投放</span>
																	}
																	{
																		(ad.approveStatus === 'SAVE' && ad.orderTotal?.disablePointCount > 0) && <span className={ `${ styles['ad-form-value-tips"'] }` }>{ ad.orderTotal?.disablePointCount }个已失效</span>
																	}
																</div>
															</div>
														</div>
													</div>
												</div>

												{/*右边操作及提醒*/}
												<div className={ `${ styles['ad-item-opts'] }` }>
													{/*计划关闭提醒*/}
													{
														(util.tools.adExecTimeoutStatusList(ad)) && (
															<div className={ `${ styles['ad-close-reason'] }` }><span className={ `${ styles['ad-close-icon'] } ifont i-tips` }></span>{ util.tools.adExecTimeoutStatusList(ad) }</div>
														)
													}

													{/*1分钟之内不显示提示*/}
													{
														(ad.approveStatus === 'PAY_WAIT' && util.tools.hasLeftTime(ad.cancelOrderTime)) && (
															<>
																<div className={ `${ styles['ad-content-tips'] }` }>{/*<span className={ `${ styles['ad-content-status'] }` }>{ util.tools.orderStatus(ad.approveStatus) }</span>*/}剩余付款时间<span className={ `${ styles['ad-end-date'] }` }>{ util.tools.convertCountDownTime(ad.cancelOrderTime) }</span></div>

																<div className={ `${ styles['btn-confirm'] }` } onClick={ () => { onPay(ad) } }>去付款</div>
															</>
															)
													}

													{/*自用、独家才有 素材提交提醒*/}
													{
														((ad.planAim === 'SELF' || ad.planAim === 'EXCLUSIVE_MEDIA') && ad.approveStatus === 'DELIVERY_WAIT' && ad.showPlanStatusMore && (ad.showPlanStatusMore?.includes('CONTENT_NULL') || ad.showPlanStatusMore?.includes('CONTENT_NOT_MATCH') || ad.showPlanStatusMore?.includes('CONTENT_NO'))) && (
															<>
																<div className={ `${ styles['ad-content-tips'] }` }>素材需在<span className={ `${ styles['ad-end-date'] }` }>{ ad.contentUploadDateEnd }</span>前提交</div>

																<div className={ `${ styles['btn-confirm'] }` } onClick={ () => { onMaterial(ad) } }>提交素材</div>
															</>
															)
													}

													{/*刊播提醒 */}
													{
														(ad.approveStatus === 'DELIVERY_WAIT' && ad.showPlanStatusMore && (!ad.showPlanStatusMore.includes('CONTENT_NULL') && !ad.showPlanStatusMore.includes('CONTENT_NOT_MATCH') && !ad.showPlanStatusMore.includes('CONTENT_NO'))) && (
															<div className={ `${ styles['ad-content-tips'] }` }>距离最早刊播日期还有<span className={ `${ styles['ad-delivery-days'] }` }>{ ad.startBetweenDays }</span>天</div>
															)
													}

													{/*刊播数据*/}
													{
														(ad.approveStatus === 'EXECUTING' && ad.hasDeliveryDays > 0) && (
															<div className={ `${ styles['ad-content-tips'] }` }>已刊播<span className={ `${ styles['ad-delivery-days'] }` }>{ ad.hasDeliveryDays }</span>天</div>
															)
													}

													{/*查看刊播数据*/}
													{
														(ad.approveStatus === 'EXECUTING' || ad.approveStatus === 'FINISHED') && (
															<div className={ `${ styles['btn-confirm'] }` } onClick={ () => { onReport(ad) } }>执行报告</div>
														)
													}

													{/*删除*/}
													{
														(ad.approveStatus === 'PAY_TIMEOUT' || ad.approveStatus === 'CANCEL_PAY' || ad.approveStatus === 'NO' || ad.approveStatus === 'CANCEL' || ad.approveStatus === 'CONTENT_TIMEOUT' || ad.approveStatus === 'TIMEOUT_P') && (
															<>
																<div className={ `${ styles['btn-confirm'] }` } onClick={ () => { onRemove(ad) } }>删除</div>
															</>
															)
													}
												</div>
	      							</div>
	      						)
	      					})
	      				}
	      			</div>
      			)
      		}
	      	
	      	{
	          // 翻页
	          (list.length > 0) && (
	            <div className={ `${ styles.paging } flex justify-end` }>
	              <Pagination 
	                showQuickJumper 
	                showSizeChanger
	                showTotal={ total => `共 ${total} 条`}
	                defaultCurrent={ defaultCurrent }
	                current={ pageNum }
	                pageSize={ pageSize }
	                pageSizeOptions={ pageSizeOptions }
	                total={ total } 
	                onChange={ onPageChange }
	                onShowSizeChange={ onShowSizeChange }
	                 />
	            </div>
	          )
	        }

	        {
	        	list.length <= 0 && <NoRecord />
	        }
      	</Spin>
      </div>

      {/*提交素材*/}
      <AddMaterialModal childRef={ addMaterialModalRef } callBack={ () => { getList(1) } } />

			{/*选择执行单类型*/}
			<SelectOrderType childRef={ selectOrderTypeModalRef } onConfirm={ onCreate } />
    </div>
  )
}