import React, { useState, useEffect, useRef } from 'react'
import { Pagination, Table, Spin, Modal, message } from 'antd'
import { useLocation, useHistory, Link } from 'react-router-dom'

import util from '../../../../libs/util'
import { AdExecReportDayDetailModal } from './report-day-detail-modal'
import { Crumb } from '../../../../components'

import styles from './List.module.css'

const { confirm } = Modal

interface Props {
  match: {
    params: {
      mallOrderMediaId?: String,
    },
  },
}

export const convertAdExecDeliveryStatus = (status) => {
  var text = '-'
  switch (status) {
    case -1:
      text = '无投放'
      break

    case 0:
      text = '刊播中'
      break

    case 1:
      text = '已完成'
      break

    case 2:
      text = '已完成'
      break

    case 3:
      text = '待刊播'
      break
  }

  return text
}

export const AdExecReportList: React.FC<Props> = (props) => {
  const baseURL = `https://${ util.tools.env }cdn.remudooh.com/`
  const history = useHistory()

  const { mallOrderMediaId } = props.match.params
  const [mallOrderPlanId, setMallOrderPlanId] = useState()
  const [isRemo, setIsRemo] = useState(false)

  const onBack = () => {
    history.go(-1)
  }

  useEffect(() => {
    getAdDetail()
  }, [])

  const [isSubmiting, setIsSubmiting] = useState(false)
  // 详情
  const [ad, setAd] = useState<any>()
  const getAdDetail = async() => {
    setIsSubmiting(true)
    const { data, code } = await util.request({
      method: 'GET',
      url: `/mall/media/order/getOrderMedia`,
      params: {
        mallOrderMediaId,
      },
    })
    if (code === 1) {
      setAd(data)
      setMallOrderPlanId(data.mallOrderPlanId)
      setIsRemo(data.planAim === 'NORMAL' || data.planAim === 'KA')
      getSellerOrderDayDetails(data)
      setIsSubmiting(false)
    }
  }

  const [list, setList] = useState()
  const [totalInfo, setTotalInfo] = useState<any>()
  // 查询点位主（卖家）订单的每日金额明细（包括合计）
  const getSellerOrderDayDetails = async(ad) => {
    const { data, code } = await util.request({
      method: 'GET',
      url: `/mall/media/order/getSellerOrderDayDetails`,
      params: {
        mallOrderMediaId,
      },
    })

    const today = util.tools.formatDate(new Date())
    // 无投放 -1 刊播中 0 已刊播 未生成报告 1 已刊播 有报告 2 待刊播 3
    data.forEach((item, index) => {
      // 是否是今天
      item.status = new Date(item.clearDate).getTime() === new Date(today).getTime() ? 0 : (item.settleStatus === 0 ? 3 : item.settleStatus)
      item.shortDate = item.clearDate.substring(5)
    })

    // 最后一条是
    setList(data.slice(0, -1))
    setTotalInfo(data.length ? data[data.length - 1] : null)
  }

  const columns = [
    {
      title: '投放日期',
      dataIndex: 'clearDate',
      key: 'clearDate',
      render(text, record, index) {
        return text
      },
    },
    {
      title: '投放点位（个）',
      dataIndex: 'pointNums',
      key: 'pointNums',
      render(text, day, index) {
        if (day.status !== -1) {
          return (
            <div className={ styles['table-cell'] }>
              { day.pointNums }
              {
                (day.settleStatus === 2 && day.workFailedNums > 0) && <div className={ `${ styles['report-tips-label'] } ${ styles['is-important'] }` }>缺刊{ parseInt(day.workFailedNums) }个</div>
              }
            </div>
          )
        }
        return text || '无投放'
      },
    },
    {
      title: '上刊率',
      dataIndex: 'workSuccessRatio',
      key: 'workSuccessRatio',
      render(text, day, index) {
        return day.settleStatus === 2 ? `${ day.workSuccessRatio * 100 }%` : '--'
      },
    },
    {
      title: '订单日收益',
      dataIndex: 'amount',
      key: 'amount',
      render(text, day, index) {
        return (
          <div className={ styles['table-cell'] }>
            {
              <div className={ styles['text-price'] }>
                ￥
                <span className={ styles['text-price-number'] }>
                { util.tools.splitNum2String(day.settleStatus === 2 ? day.paymentAmount : day.payableAmount).int }
                { util.tools.splitNum2String(day.settleStatus === 2 ? day.paymentAmount : day.payableAmount).dec }
                </span>
              </div>
            }
            {
              (day.refundAmount > 0) && (<span className={ `${ styles['report-tips-label'] } ${ styles['is-important'] }` }>已退{ util.tools.splitNum2String(day.refundAmount).int }{ util.tools.splitNum2String(day.refundAmount).dec }</span>)
            }
            {
              (day.settleStatus === 0 || day.settleStatus === 1) && (<span className={ styles['report-tips-label'] }>预计</span>)
            }
          </div>
        )
      },
    },
    {
      title: '日刊播状态',
      dataIndex: 'status',
      key: 'status',
      render(text, day, index) {
        return (
          <span className={ `${ styles['report-status'] } ${ day.settleStatus === 0 ? styles['warning'] : (day.settleStatus === 2) ? styles['success'] : styles['gray'] }` }>{ convertAdExecDeliveryStatus(day.status) }</span>
        )
      },
    },
    {
      title: '日收益结算状态',
      dataIndex: 'settleStatus',
      key: 'settleStatus',
      render(text, day, index) {
        return (
          <>
            { (day.settleStatus === 0) && <span className={ styles['warning'] }>未结算</span> }
            {
              (day.settleStatus === 2) && <span className={ styles['success'] }>已结算</span>
            }
            { (day.status === -1) && <span className={ styles['gray'] }>--</span> }
          </>
        )
      },
    },
    {
      title: '操作',
      dataIndex: 'options',
      key: 'options',
      render(_, day) {
        return (
          <>
          {
            (day.status !== -1) && <span className={ styles['btn-table-link'] } onClick={ () => showModal(day) }>查看日刊播详情</span>
          }
          </>
        )
      },
    },
  ]

  const resultColumns = isRemo ? columns : columns.filter(i => i.dataIndex !== 'amount' && i.dataIndex !== 'settleStatus')

  const reportDayDetailModalRef: any = useRef()
  const showModal = (record) => {
    const params = {
      mallOrderMediaId,
      pointDate: record.clearDate,
    }
    reportDayDetailModalRef.current.onToggle(params, record)
  }

  const crumbAdExecList = [
    {
      name: '广告执行单',
      path: '/ad-exec/list',
    },
    {
      name: '广告执行单详情',
      path: `/ad-exec/media-detail/${ mallOrderPlanId }`,
    },
    {
      name: '执行报告',
      path: ``,
    }
  ]

  return (
    <div className={ `${ styles['ad-page'] }` }>
      <Crumb list={ crumbAdExecList } />

      {/*广告计划名称*/}
      <div className={ `${ styles['ad-page-cont'] }` }>
        <div className={ `${ styles['ad-page-tit'] }` }>
          <div className={ `${ styles['btn-back'] }` } onClick={ onBack }>
            <div className={ `${ styles['icon-right'] } imediafont i-trangle-b` }></div>
            <div className={ `${ styles['btn-back-text'] }` }>返回</div>
          </div>
          <div className={ `${ styles['ad-page-tit-text'] }` }>每日执行情况</div>
        </div>

        <div className={ `${ styles['ad-form'] }` }>
          <div className={ `${ styles['ad-form-item'] }` }>
            <div className={ `${ styles['ad-form-label'] }` }>
              <div className={ `${ styles['ad-form-icon'] } icon icon-calendar` }></div>
              投放周期：
            </div>
            <div className={ `${ styles['ad-form-value'] }` }>
              { util.tools.formatDateStr(ad?.deliveryStart) }-{ util.tools.formatDateStr(ad?.deliveryEnd) }<span className={ `${ styles['text-days'] }` }>({ ad?.deliveryDays }天)</span>
            </div>
          </div>

          {/*TODO*/}
          {/*<div className={ `${ styles['ad-form-item'] }` }>
            <div>查看</div>
          </div>*/}
        </div>

        <div className={ `${ styles['report-list'] }` }>
          <Table
            rowKey="clearDate"
            columns={ resultColumns }
            dataSource={ list }
            pagination={ false }
          ></Table>
        </div>

      </div>

      <AdExecReportDayDetailModal childRef={ reportDayDetailModalRef } isRemo={ isRemo }  />
    </div>
  )
}