export const operatorTypeItems = [ 
  {
    itemKey: 'CINEMA',
    itemValue: '影院银幕广告',
    text: '视听震撼、强势曝光、受众优质、覆盖范围广',
  },
  {
    itemKey: 'NORMAL',
    itemValue: 'LCD智能屏广告',
    text: '核心位置、人流密集、高频触达、智能监播',
  },
]

export const deliveryCinemaModelItems = [
  {
    itemKey: 'DELIVERY_HALL',
    itemValue: '通投',
  },
  {
    itemKey: 'DELIVERY_FILM',
    itemValue: '跟片',
  },
]

// 执行单状态
export const adExecStatus = function(value) {
  switch (value) {
    case 'PAY_WAIT': return { text: '待付款', class: 'warn' }
    case 'DELIVERY_WAIT': return { text: '待上刊', class: 'warn' }
    case 'EXECUTING': return { text: '刊播中', class: 'warn' }
    case 'FINISHED': return { text: '已完成', class: 'warn' }
    case 'ORDER_TIMEOUT': return { text: '执行单关闭', class: 'warn' }
    default: return { text: '执行单关闭', class: 'warn' }
  }
}

export const transactionTypeNames = [
  {
    itemKey: 'ADD',
    itemValue: '充值',
  },
  {
    itemKey: 'REVERSE_ADD',
    itemValue: '充值退回',
  },
  {
    itemKey: 'REVERSE_PAY',
    itemValue: '支付回退',
  },
  {
    itemKey: 'REVERSE_REFUND',
    itemValue: '退款退回',
  },
  {
    itemKey: 'PAY',
    itemValue: '广告执行单-智能刊播服务费',
  },
  {
    itemKey: 'REFUND',
    itemValue: '广告执行单-智能刊播服务费退款',
  },
]
export const appIdItems = [
  {
    itemKey: 'BANK_ICBC',
    itemValue: '中国工商银行',
  },
  {
    itemKey: 'BANK_CCB',
    itemValue: '中国建设银行',
  },
  {
    itemKey: 'BANK_BOC',
    itemValue: '中国交通银行',
  },
  {
    itemKey: 'WECHAT_PAY',
    itemValue: '微信',
  },
  {
    itemKey: 'ALI_PAY',
    itemValue: '支付宝',
  },
  {
    itemKey: 'SYSTEM_GRANT',
    itemValue: '系统发放',
  },
  {
    itemKey: 'ACT_GRANT',
    itemValue: '活动发放',
  },
]
export const appProductItems = [
  {
    itemKey: 'OFFLINE_TRANSFER',
    itemValue: '代充',
  },
  {
    itemKey: 'ONLINE_PAY',
    itemValue: '直充',
  },
  {
    itemKey: 'SYSTEM_GRANT',
    itemValue: '系统发放',
  },
  {
    itemKey: 'ACT_GRANT',
    itemValue: '活动发放',
  },
]
// 退款原因
export const refundReasonItems = [
  {
    itemKey: 'mall_media_approve',
    itemValue: '不同意上刊退款',
  },
  {
    itemKey: 'mall_platform_approve',
    itemValue: '不同意上刊退款',
  },
  {
    itemKey: 'mall_funds_settle',
    itemValue: '任务未执行退款',
  },
]

// ADD 充值;REVERSE_ADD 充值退回;PAY 支付;REVERSE_PAY 支付退回;REFUND 退回;REVERSE_REFUND 退回退回;WITHDRAW 提现;REVERSE_WITHDRAW 提现退回;
export const transactionTypeItems = [
  {
    itemKey: 'ADD',
    itemValue: '充值',
  },
  {
    itemKey: 'REVERSE_ADD',
    itemValue: '充值退回',
  },
  {
    itemKey: 'PAY',
    itemValue: '支付',
  },
  {
    itemKey: 'REVERSE_PAY',
    itemValue: '支付回退',
  },
  {
    itemKey: 'REFUND',
    itemValue: '退款',
  },
  {
    itemKey: 'REVERSE_REFUND',
    itemValue: '退款退回',
  },
  {
    itemKey: 'WITHDRAW',
    itemValue: '提现',
  },
  {
    itemKey: 'REVERSE_WITHDRAW',
    itemValue: '提现失败退款',
  },
]

// 审核状态
// "APPROVE_WAIT待处理(待审核)、DELIVERY_WAIT待上刊(至少一个通过)、EXECUTING执行中、FINISHED已完成；交易关闭：NO未通过（全部）、CANCEL取消投放、CONTENT_TIMEOUT广告主内容提供超时"
export const approveStatusList = [
  {
    itemKey: 'APPROVE_WAIT',
    itemValue: '待接单',
  },
  {
    itemKey: 'DELIVERY_WAIT',
    itemValue: '待上刊',
  },
  {
    itemKey: 'EXECUTING',
    itemValue: '刊播中',
  },
  {
    itemKey: 'FINISHED',
    itemValue: '已完成',
  },
  {
    itemKey: 'NO,TIMEOUT,CANCEL,CONTENT_TIMEOUT,TIMEOUT_P',
    itemValue: '交易关闭',
  },
]

export const CinemaScreenTypeItems = [
  {
    itemKey: 'SCOPE',
    itemValue: '宽幅影幕',
    tips: '2.39:1',
    text: '',
  },
  {
    itemKey: 'FLAT',
    itemValue: '遮幅银幕',
    tips: '1.85:1',
    text: '',
  },
]

export const VideoInfo = {
  videMaxDuration: 60,
  videoMaxSize: 1000, // 1000M
  minWidth: 1920,
  minHeight: 820,
  bestWidth: 2048,
  bestHeight: 858,
  bestRatio: 2.39,
  minRatio: 2.35,
  videoType: ['mp4', 'mkv', 'avi', 'mov'],
}

export const OrderTypeList = [
  {
    id: 'EXCLUSIVE_MEDIA',
    planAimExt: null,
    name: '代理商独家包销',
    serviceList: [
      {
        id: 'base',
        name: '基础服务',
        tips: '免费',
        list: ['素材云传输分发', '自动DCP转码', '自动排播'],
      },
      {
        id: 'other',
        name: '智能刊播服务',
        hasFee: true,
        tips: '元/天/点位/15秒广告',
        list: ['素材AI + 专业人工双重审核', '自动监播', '投放报告'],
      },
    ],
  },
  {
    id: 'SELF',
    planAimExt: null,
    name: '影院自营广告',
    serviceList: [
      {
        id: 'base',
        name: '基础服务',
        tips: '免费',
        list: ['素材云传输分发', '自动DCP转码', '自动排播'],
      },
      {
        id: 'other',
        name: '智能刊播服务',
        hasFee: true,
        tips: '元/天/点位/15秒广告',
        list: ['素材AI + 专业人工双重审核', '自动监播', '投放报告'],
      },
    ],
  },
  {
    id: 'SELF',
    planAimExt: 0,
    name: '影院内宣广告',
    serviceList: [
      {
        id: 'base',
        name: '基础服务',
        tips: '免费',
        list: ['素材云传输分发', '自动DCP转码', '自动排播'],
      },
    ],
  },
]