import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { renderRoutes } from 'react-router-config'

import { useHistory } from 'react-router-dom'
import { AssistantHeader } from '../../components'

import { UPDATE_USER } from '../../store/actionTypes'
import { defaultState as defaultUserState } from '../../store/user/reducer'

import util from '../../libs/util'

export const AssistantMainLayout: React.FC = (props: any) => {
  const history = useHistory()
  
  const dispatch = useDispatch()
  useEffect(() => {
    // 清除登录数据
    const clearLogData = () => {
      dispatch({
        type: UPDATE_USER,
        payload: defaultUserState,
      })
      console.log('clearLogData', util.Cookies.get('userToken'))
      util.storage.clear()
      util.Cookies.remove('userToken')
      history.replace('/assistant/login')
    }
    util.bus.on('UNAUTH', clearLogData)

    return function() {
      util.bus.off('UNAUTH', clearLogData)
    }
  }, [])

  return (
    <>
      <AssistantHeader />
      { renderRoutes(props.route.routes) }
      {/*{ props.children }*/}
    </>
  )
}