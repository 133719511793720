import React, { useState, useEffect, useRef } from 'react'
import { Spin, message } from 'antd'
import { useLocation, useHistory, Link } from 'react-router-dom'

import util from '../../../libs/util'
import { OrderTypeList } from '../../../libs/util.constants'

import { CreateForm } from '../components/create-form'
import { Crumb } from '../../../components'

import styles from './AdCreate.module.css'

const defaultTabList = [
  {
    id: 'SELF',
    name: '影院线下自营',
  },
  {
    id: 'EXCLUSIVE_MEDIA',
    name: '代理商独家包销',
  },
]

const defaultSubmitForm = {
  planAim: 'SELF',
  standardType: 'CPT',
  deliveryModel: 'SPLIT',
  operatorType: 'CINEMA',
  // 通投DELIVERY_HALL 跟片DELIVERY_FILM
  deliveryCinemaModel: 'DELIVERY_HALL',
  administrativeAreaId: [],
  deliveryStart: '',
  deliveryEnd: '',
  deliveryContentDuration: 0,
  deliveryIndustry: '',
  timePeriods: [],
  playSequence: 99,
  customerId: null,
  planAimExt: null,
}

interface Props {
  match: {
    params: {
      planAim?: String,
      planAimExt?: String,
    },
  },
}

export const AdExecCreate: React.FC<Props> = (props) => {
  const baseURL = `https://${ util.tools.env }cdn.remudooh.com/`
  // const [planAim, setPlanAim] = useState(props.match.params.planAim)
  // const [planAimExt, setPlanAimExt] = useState(props.match.params.planAimExt)
  const {planAim, planAimExt } = props.match.params
  const history = useHistory()

  const crumbList = [
    {
      name: '广告执行单',
      path: '/ad-exec/list',
    },
    {
      name: '创建广告执行单',
      path: '',
    }
  ]

  const onBack = () => {
    history.push(`/ad-exec/list`)
  }

  const [tabList, setTabList] = useState(defaultTabList)
  const [curTabId, setCurTabId] = useState(defaultTabList[0].id)
  const [curSubTabId, setCurSubTabId] = useState('DELIVERY_HALL')

  const [submitForm, setSubmitForm] = useState<any>(Object.assign({}, defaultSubmitForm, {
    planAim,
    planAimExt: planAimExt === 'null' ? null : 0,
  }))

  const toggleTab = (id) => {
    if (curTabId === id) {
      return
    }
    setCurTabId(id)
    setSubmitForm((state) => {
      return {
        ...state,
        planAim: id,
      }
    })
  }

  return (   
    <div className={ `${ styles['ad-page'] }` }>
      <Crumb list={ crumbList } />

      {/*广告计划名称*/}
      <div className={ `${ styles['ad-page-cont'] }` }>
        <div className={ `${ styles['ad-page-tit'] }` }>
          <div className={ `${ styles['btn-back'] }` } onClick={ onBack }>
            <div className={ `${ styles['icon-right'] } imediafont i-trangle-b` }></div>
            <div className={ `${ styles['btn-back-text'] }` }>返回</div>
          </div>
          <div className={ `${ styles['ad-page-tit-text'] }` }>创建广告执行单</div>
        </div>

        <div className={ `${ styles['ad-create'] }` }>
          {/* <div className={ `${ styles['tabs'] }` }>
            {
              tabList.map((tab, index) => {
                return (
                  <div 
                    className={ `${ styles['tab-item'] } ${ curTabId === tab.id ? styles['is-selected'] : '' }` } 
                    key={ tab.id }
                    onClick={ () => { toggleTab(tab.id) } }
                    >
                    { tab.name }
                  </div>
                )
              })
            }
          </div>

          <div className={ `${ styles['subtabs'] }` }>
            {
              deliveryCinemaModelItems.map((tab, index) => {
                return (
                  <div className={ `${ styles['subtab-item'] } ${ curSubTabId === tab.itemKey ? styles['is-selected'] : '' }` } key={ tab.itemKey }>
                    { tab.itemValue }
                  </div>
                )
              })
            }
          </div> */}

          <div className={ `${ styles['ad-create-type'] }` }>{ util.tools.getAdExecType(OrderTypeList, { planAim, planAimExt }) }</div>
          
          {/* 表单 */}
          <CreateForm form={ submitForm } />
        </div>
        
      </div>
    </div>
  )
}