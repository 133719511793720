import React, { useState, useEffect, useRef, useImperativeHandle } from 'react'
import { Pagination, Spin, Modal, message, Checkbox } from 'antd'
import { useLocation, useHistory, Link } from 'react-router-dom'

import util from '../../../../libs/util'
import { operatorTypeItems, deliveryCinemaModelItems } from '../../../../libs/util.constants'
import { PointPriceDetailModal } from '../point-price-detail-modal'

import { AdExec, defaultAdExec } from '../../index'
import styles from './EntityList.module.css'

const CheckboxGroup = Checkbox.Group

interface Props {
  ad: AdExec,
  isConfirmPage?: boolean,
  childRef?: any,
  toggleBottom?: Function,
  reloadFn?: Function,
}

interface Item {
  itemKey: string,
  itemValue: string,
}

export const EntityList: React.FC<Props> = ({ ad, childRef, isConfirmPage, toggleBottom, reloadFn }) => {
  useImperativeHandle(childRef, () => {
    return {
      init() {
        getEntityList(1)
      },
    }
  })

	const baseURL = `https://${ util.tools.env }cdn.remudooh.com/`
  const history = useHistory()

  const [isEditMode, setIsEditMode] = useState(false)
  const [isSelfMedia, setIsSelfMedia] = useState(ad?.planAim === 'SELF' || ad?.planAim === 'EXCLUSIVE_MEDIA')
  const [canEditPointList, setCanEditPointList] = useState((ad?.showPlanStatus === 'DRAFT' || ad?.showPlanStatus === 'SAVE') && !isConfirmPage)

  const [mallOrderPlanId, setMallOrderPlanId] = useState(ad?.mallOrderPlanId)
  const [mallOrderMediaId, setMallOrderMediaId] = useState(ad?.mallOrderMediaId)
  const [mallMediaApproveId, setMallMediaApproveId] = useState(ad?.mallMediaApproveId)

  useEffect(() => {
    // console.log('pageNum, mallOrderMediaId', pageNum, mallOrderMediaId)
    // if (!ad.showPlanStatus) {
    //   return
    // }
    // setCanEditPointList()
    // setIsSelfMedia()
    // setMallOrderPlanId( || '')
    // setMallOrderMediaId( || '')
    // setMallMediaApproveId( || '')
    getEnumItems()
  }, [])

  // 场所列表
  const [entityList, setEntityList] = useState<any[]>([])
  const [defaultCurrent, setDefaultCurrentTotal] = useState(1)
  const [pageNum, setPageNum] = useState(1)
  const [pageSizeOptions, setPageSizeOptions] = useState(['10', '20', '30'])
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  useEffect(() => {
    if (!ad.showPlanStatus && (ad.planAim === 'SELF' || ad.planAim === 'EXCLUSIVE_MEDIA')) {
      return
    }
    getEntityList(pageNum)
  }, [pageNum, ad.planAim])
  useEffect(() => {
    setPageNum(defaultCurrent)
  }, [pageSize])

  const [isSubmiting, setIsSubmiting] = useState(false)
  const getEntityList = async (num?: number) => {
    if (num) {
      setPageNum(num)
    }
    setIsSubmiting(true)
    // console.log('ad', ad)
    
    const pNum = num || pageNum
    const url = !isSelfMedia ? `/mall/media/order/searchOrderMediaEntityList?pageNum=${pageNum}&pageSize=${pageSize}` : `/mall/order/searchOrderEntityList?pageNum=${pageNum}&pageSize=${pageSize}`
    const params = !isSelfMedia ? {
      mallOrderMediaId,
      mallMediaApproveId,
    } : {
      mallOrderPlanId,
    }
    const { data = [], total = 0, code } = await util.request({
      method: !isSelfMedia ? 'GET' : 'POST',
      url,
      data: mallOrderPlanId ? params : null,
      params: !isSelfMedia ? params : null,
    })
    if (code === 1) {
      setEntityList(data)
      data.forEach((entity, index) => { 
        getPointList(data, index)
      })
      setTotal(total)
    }
  }

  // 点位列表
  const getPointList = async (list, entityIndex) => {
    let eList = [...list]
    const resourceEntityInfoId = eList[entityIndex].resourceEntityInfoId
    const url = !isSelfMedia ? `/mall/media/order/searchOrderMediaPointList?pageNum=1&pageSize=9999` : `/mall/order/searchOrderPointList?pageNum=1&pageSize=9999`
    const params = !isSelfMedia ? {
      mallOrderMediaId,
      mallMediaApproveId,
      resourceEntityInfoId,
    } : {
      mallOrderPlanId,
      resourceEntityInfoId,
    }
    const { data = [], code } = await util.request({
      method: !isSelfMedia ? 'GET' : 'POST',
      url,
      data: !isSelfMedia ? null : params,
      params: !isSelfMedia ? params : null,
    })

    if (code === 1) {
      eList[entityIndex].pointList = data
      setEntityList(eList)
    }
    setIsSubmiting(false)
  }

  const [cinemaScreenMaterialItems, setCinemaScreenMaterialItems] = useState<Item[]>([])
  const [cinemaSoundTrackItems, setCinemaSoundTrackItems] = useState<Item[]>([])
  // 获取枚举值
  const getEnumItems = async () => {
    // CINEMA_SOUND_TRACK 声道
    // CINEMA_SCREEN_MATERIAL 影院银幕类型
    const { data, total = 0, code } = await util.request({
      method: 'GET',
      url: `/config/enum/findEnumItemList`,
      params: {
        groupKeyArray: ['CINEMA_SOUND_TRACK', 'CINEMA_SCREEN_MATERIAL'].join(','),
      },
    })
    if (code === 1) {
      setCinemaScreenMaterialItems(data['CINEMA_SCREEN_MATERIAL'] || [])
      setCinemaSoundTrackItems(data['CINEMA_SOUND_TRACK'] || [])
    }
  }

  // 页码变化
  const onPageChange = (page, size) => {
    if (pageSize !== size) {
      return
    }
    setPageNum(page)
  }
  const onShowSizeChange = (current, size) => {
    setPageSize(size)
  }
  // 去选点
  const goToSelectPage = () => {
    history.push(`/ad-exec/select-points/${ ad.mallOrderPlanId }`)
  }

  // 重新选点
  const goToHallPage = (entity) => {
    const filterData = {
      mallOrderPlanId: ad.mallOrderPlanId,
      administrativeAreaId: ad?.districtList?.map(i => i.districtId),
      geoDistance: entity.geoDistance,
      cinemaChainName: [],
      usableSearch: true,
    }

    const id = entity.resourceEntityInfoId

    history.push(`/ad-exec/select-hall/${ mallOrderPlanId }/${ JSON.stringify(filterData) }/${ id }/${ ad.deliveryContentDuration }/${ ad.deliveryDays }/${ JSON.stringify(ad.timePeriods) }/${ ad.showPlanStatus }/${ ad.deliveryModel }/${ ad.planAim }`)
  }

  const toggleEditMode = () => {
    toggleBottom && toggleBottom(isEditMode)
    setIsEditMode(!isEditMode)
  }

  const [selectedPointCount, setSelectedPointCount] = useState(ad?.orderTotal?.pointCount)
  const [selectedEntityCount, setSelectedEntityCount] = useState(ad?.orderTotal?.entityCount)
  const [checkedEntityList, setCheckedEntityList] = useState<any>([])
  const [indeterminate, setIndeterminate] = useState(false)
  const [checkAll, setCheckAll] = useState(false)
  const onEntityChange = (list) => {
    setCheckedEntityList(list)
    setIndeterminate(!!list.length && list.length < entityList.length)
    setCheckAll(list.length === entityList.length)
  }
  // 全选所有
  const onCheckAllChange = (e) => {
    const checked = e?.target?.checked
    setCheckedEntityList(checked ? entityList.map(i => i.resourceEntityInfoId) : [])
    setIndeterminate(false)
    setCheckAll(checked)
    entityList.forEach(entity => {
      entity.isChecked = checked
      entity.pointList.forEach(point => {
        point.isChecked = checked
      })
      entity.checkedPointList = checked ? entity.pointList.map(i => i.resourcePointId) : []
    })
    setEntityList([...entityList])
    setSelectedEntityCount(checked ? entityList.length : 0)
    const count = entityList.map(i => i.checkedPointList ? i.checkedPointList.length : 0).reduce((pre, cur) => {
      return pre + cur
    }, 0)
    setSelectedPointCount(count)
  }
  const toSetCount = (isChecked, notSetEntity?) => {
    
    if (!notSetEntity) {
      
      console.log('toSetCount', selectedEntityCount, isChecked ? selectedEntityCount + 1 : selectedEntityCount - 1)
    }
    setSelectedEntityCount(isChecked ? selectedEntityCount + 1 : selectedEntityCount - 1)
    const count = entityList.map(i => i.checkedPointList ? i.checkedPointList.length : 0).reduce((pre, cur) => {
      return pre + cur
    }, 0)
    setSelectedPointCount(count)
  }

  // 全选点位
  const onEntityCheckAllChange = (e) => {
    const isChecked = e?.target?.checked
    const resourceEntityInfoId = e?.target?.value
    const entityIndex = entityList.findIndex(i => i.resourceEntityInfoId === resourceEntityInfoId)
    const entity = entityList[entityIndex]

    entity.isChecked = isChecked
    entity.checkedPointList = isChecked ? entity.pointList.map(i => i.resourcePointId) : []
    entity.indeterminateEntity = false
    setEntityList([...entityList])

    setSelectedEntityCount(isChecked ? selectedEntityCount + 1 : selectedEntityCount - 1)
    const count = entityList.map(i => i.checkedPointList ? i.checkedPointList.length : 0).reduce((pre, cur) => {
      return pre + cur
    }, 0)
    setSelectedPointCount(count)

    console.log('onEntityCheckAllChange', e, isChecked ? selectedEntityCount + 1 : selectedEntityCount - 1,)
  }
  // 单个点位
  const onEntityCheckboxChange = (list, resourceEntityInfoId) => {
    const entityIndex = entityList.findIndex(i => i.resourceEntityInfoId === resourceEntityInfoId)
    const entity = entityList[entityIndex]
    const isChecked = list.length === entity.pointList.length

    // 如果全选了场所下的所有点位,则把场所的id加入 checkedEntityList
    // 否则 则把场所的id从 checkedEntityList里取出
    const tempCheckedEntityList = isChecked ? [...checkedEntityList, resourceEntityInfoId] : checkedEntityList.filter(i => i !== resourceEntityInfoId)

    const isPointChecked = list.length > (entity.checkedPointList ? entity.checkedPointList.length : 0)

    console.log('onEntityCheckboxChange', list, resourceEntityInfoId, isPointChecked)
    // 不可选厅，只能全选/全不选
    if (entity.fullSelect) {
      const content = !isPointChecked ? '是否确认取消选择所有影厅？' : '是否确认选择所有影厅？'
      Modal.confirm({
        title: '该影院不可选厅投放，只可投放全部影厅',
        content,
        cancelText: '取消',
        okText: '确认',
        onOk() {
          toggleAllToEntity(isPointChecked, entityIndex, isPointChecked)
        },
        onCancel() {
        },
      })
      return
    }

    entity.indeterminateEntity = !!list.length && list.length < entity.pointList.length
    entity.isChecked = isChecked
    entity.checkedPointList = list

    const entityCount = entityList.map(i => i.checkedPointList && i.checkedPointList.length ? 1 : 0).reduce((pre: any, cur: any) => {
      return pre + cur
    }, 0)
    setSelectedEntityCount(entityCount)

    const count = entityList.map(i => i.checkedPointList ? i.checkedPointList.length : 0).reduce((pre, cur) => {
      return pre + cur
    }, 0)
    setSelectedPointCount(count)

    setEntityList([...entityList])
    setCheckedEntityList(tempCheckedEntityList)
    setIndeterminate(!!tempCheckedEntityList.length && tempCheckedEntityList.length < entityList.length)
    setCheckAll(tempCheckedEntityList.length === entityList.length)

    // console.log('onEntityCheckboxChange', list, entity.indeterminateEntity,  tempCheckedEntityList)
  }

  const toggleAllToEntity = (isChecked, entityIndex, isPointChecked) => {
    const entity = entityList[entityIndex]
    const resourceEntityInfoId = entity.resourceEntityInfoId
    
    entity.indeterminateEntity = false
    entity.isChecked = isChecked
    entity.checkedPointList = isChecked ? entity.pointList.map(i => i.resourcePointId) : []

    const tempCheckedEntityList = isChecked ? [...checkedEntityList, resourceEntityInfoId] : checkedEntityList.filter(i => i !== resourceEntityInfoId)

    const entityCount = entityList.map(i => i.checkedPointList && i.checkedPointList.length ? 1 : 0).reduce((pre: any, cur: any) => {
      return pre + cur
    }, 0)
    setSelectedEntityCount(entityCount)

    const count = entityList.map(i => i.checkedPointList ? i.checkedPointList.length : 0).reduce((pre, cur) => {
      return pre + cur
    }, 0)
    setSelectedPointCount(count)

    setEntityList([...entityList])
    setCheckedEntityList(tempCheckedEntityList)
    setIndeterminate(!!tempCheckedEntityList.length && tempCheckedEntityList.length < entityList.length)
    setCheckAll(tempCheckedEntityList.length === entityList.length)
  }

  const onDel = async() => {
    if (!selectedPointCount || isSubmiting) {
      return
    }
    setIsSubmiting(true)
    let entityInfoList:any = []
    let params = {
      entityInfoList: [],
      mallOrderPlanId,
      selectAll: checkAll ? 'YES' : 'NO',
    }
    console.log(indeterminate, checkedEntityList)
    // 选了部分
    if (indeterminate || entityList.find(i => i.indeterminateEntity)) {
      entityList.forEach(entity => {
        const isEntityAllChecked = !entity.indeterminateEntity && entity.indeterminateEntity !== undefined
        console.log(entity)
        if (isEntityAllChecked) {
        // 选了全部点位
          entityInfoList.push({
            selectModel: 'ALL',
            resourceEntityInfoId: entity.resourceEntityInfoId,
            selectedPointId: [],
          })
        } else if (entity.indeterminateEntity !== undefined) {
        // 有选了部分点位
          entityInfoList.push({
            selectModel: 'PART',
            resourceEntityInfoId: entity.resourceEntityInfoId,
            selectedPointId: entity.checkedPointList,
          })
        }
      })
      params.entityInfoList = entityInfoList
    }

    console.log('entityInfoList', entityInfoList)
    const { data, message: msg, code } = await util.request({
      method: 'POST',
      url: `/mall/order/removeOrderPoint`,
      data: params,
    })
    if (code === 1) {
      message.success('删除成功')
      reloadFn && reloadFn()
    } else {
      message.error(msg || '操作失败')
    }
    setIsSubmiting(false)
  }

  const pointPriceDetailModalRef: any = useRef()
  const showPriceModal = (point) => {
    const params = {
      resourcePointId: point.resourcePointId, 
      mallOrderPlanId,
      mallOrderMediaId,
      showPlanStatus: ad.showPlanStatus,
      deliveryDays: ad.deliveryDays,
    }
    pointPriceDetailModalRef.current.onToggle(params)
  }

  return (
    <>
  	<div className={ `${ styles['ad-page-cont'] }` }>
      <div className={ `${ styles['flex'] }` }>
        <div className={ `${ styles['ad-tit-label'] }` }>投放点位</div>
        {
          (!canEditPointList) && (
            <div className={ `${ styles['ad-tit-tips'] }` }>共 <span className={ `${ styles['important'] }` }>{ ad?.orderTotal?.pointCount }</span> 个点位，覆盖 <span className={ `${ styles['important'] }` }>{ ad?.orderTotal?.entityCount }</span> 个场所</div>
          )
        }

        {
          (canEditPointList) && (
            <div className={ `${ styles['btn-manage'] }` } onClick= { toggleEditMode }>{ isEditMode ? '完成' : '管理' }</div>
          )
        }
      </div>

      {
        canEditPointList && <div className={ `${ styles['btn-select-points'] } icon` } onClick={ goToSelectPage }>选点</div>
      }

      {
        (entityList.length > 0) && (
          <>
            {/* 场所列表 */}
            <div className={ `${ styles['table-head'] }` }>
              <div className={ `${ styles['table-td'] }` }>场所信息</div>
              <div className={ `${ styles['table-td'] }` }>点位名称</div>
              <div className={ `${ styles['table-td'] }` }>座位数</div>
              <div className={ `${ styles['table-td'] }` }>声道</div>
              <div className={ `${ styles['table-td'] }` }>影厅类型</div>
              <div className={ `${ styles['table-td'] }` }>可投放天数</div>
              <div className={ `${ styles['table-td'] }` }>操作</div>
            </div>

            <div className={ `${ styles['entity-list'] }` }>
              <Checkbox.Group style={{ width: '100%' }} value={ checkedEntityList } onChange={ onEntityChange }> 
              {
                entityList.map(entity => {
                  return (
                    <div className={ `${ styles['entity-item'] }` } key={ entity.resourceEntityInfoId }>
                      <div className={ `${ styles['entity-info-wrap'] }` }>
                        {
                          isEditMode && <div className={ `${ styles['entity-checkbox'] }` }>
                          <Checkbox value={ entity.resourceEntityInfoId } indeterminate={ entity.indeterminateEntity } onChange={ onEntityCheckAllChange } checked={ entity.isChecked } style={{ 'fontSize': '16px' }}></Checkbox>
                          </div>
                        }
                        <div className={ `${ styles['entity-image-cont'] }` }>
                          <img className={ `${ styles['entity-thumbnail'] }` } src={ `${ entity.entityViewPath }?imageMogr2/thumbnail/x112` } />
                          {
                            (entity.operatorEntityType === 'CINEMA') && (
                              <div className={ `${ styles['entity-label'] }` }>{ entity.cinemaChainName }</div>
                            )
                          }
                        </div>
                        <div className={ `${ styles['entity-info'] }` }>
                          <div className={ `${ styles['entity-tit'] }` }>{ entity.entityName }</div>
                          {
                            (entity?.cinemaUnifiedLink && entity?.cinemaUnifiedLink !== 'MANUAL_AGENT') && (
                              <div className={ `${ styles['entity-auto-check'] }` }>自动化上刊</div>
                            )
                          }
                          
                          <div className={ `${ styles['entity-dec'] }` }>
                            { entity.cinemaAttendance }km
                            | &nbsp;
                            { entity.provinceName }
                            {
                              (entity.cityName) && (<> { entity.cityName } </>)
                            }
                            {
                              (entity.districtName) && (<> { entity.districtName } </>)
                            }
                            { entity.addressMore }
                          </div>
                          <div className={ `${ styles['entity-point-info'] }` }>已选点位<span className={ `${ styles['count-num'] }` }>{ entity.entityPointCount || entity.orderEntityState.pointCount || 0 }</span>个
                          </div>
                          <div className={ `${ styles['entity-prirce-info'] }` }>小计 <span className={ `${ styles['entity-prirce-text'] }` }>￥<span className={ `${ styles['entity-prirce-int'] }` }>{ (ad.planAim === 'SELF' || ad.planAim === 'EXCLUSIVE_MEDIA') ? util.tools.splitNum2String(entity.orderEntityState.salePrice).int : 0 }
                          </span>{ (ad.planAim === 'SELF' || ad.planAim === 'EXCLUSIVE_MEDIA') ? util.tools.splitNum2String(entity.orderEntityState.salePrice).dec : '' }</span></div>

                          {
                            canEditPointList && <div className={ `${ styles['btn-reselect'] }` } onClick={ () => { goToHallPage(entity) } }>重新选点</div>
                          }
                          
                        </div>
                      </div>

                      <div className={ `${ styles['point-list'] }` }>
                      <Checkbox.Group style={{ width: '100%' }} value={ entity.checkedPointList } onChange={ (e) => { onEntityCheckboxChange(e, entity.resourceEntityInfoId) } }>
                        {
                          (entity.pointList && entity.pointList.length > 0) && (
                            entity.pointList.map(point => {
                              return (
                                <div className={ `${ styles['point-item'] }` } key={ point.resourcePointId }>
                                  {
                                    isEditMode && <div className={ `${ styles['point-checkbox'] }` }><Checkbox value={ point.resourcePointId } checked={ point.isChecked }></Checkbox></div>
                                  }
                                  <div className={ `${ styles['table-td'] }` }>{ point.pointName }</div>
                                  <div className={ `${ styles['table-td'] }` }>{ point.seatNumber }座</div>
                                  <div className={ `${ styles['table-td'] }` }>{ cinemaSoundTrackItems?.find(i => i.itemKey === point.cinemaSoundTrack)?.itemValue }</div>
                                  <div className={ `${ styles['table-td'] }` }>{ cinemaScreenMaterialItems?.find(i => i.itemKey === point.cinemaScreenMaterial)?.itemValue }</div>
                                  <div className={ `${ styles['table-td'] }` }><span className={ `${ styles['point-total-days'] } ${ point.innerTotal && point.innerTotal != ad.deliveryDays ? styles['special-number'] : '' }` }>{ point.innerTotal }</span>天</div>
                                  <div className={ `${ styles['table-td'] } ${ styles['table-link'] }` } onClick={ () => { showPriceModal(point) } }>查看投放日期</div>
                                </div>
                              )
                            })
                          )
                        }
                      </Checkbox.Group>
                        
                      </div>
                    </div>
                  )
                })
              }

              </Checkbox.Group>
            </div>

            {/*页码*/}
            <div className={ `${ styles.paging } flex justify-end` }>
              <Pagination 
                showQuickJumper 
                showSizeChanger
                showTotal={ total => `共 ${total} 条`}
                defaultCurrent={ defaultCurrent }
                current={ pageNum }
                pageSize={ pageSize }
                pageSizeOptions={ pageSizeOptions }
                total={ total } 
                onChange={ onPageChange }
                onShowSizeChange={ onShowSizeChange }
                 />
            </div>
          </>
        )
      }

      {
        (!entityList.length) && (
          <div className={ `${ styles['no-record'] }` }>暂无投放点位，请先选点</div>
        )
      }
    </div>
    {
      (entityList.length > 0 && isEditMode) && (
        <div className={ `${ styles['entity-bottom'] }` }>
          <div className={ `${ styles['entity-bottom-l'] }` }>
            <Checkbox 
              indeterminate={ indeterminate } onChange={ onCheckAllChange } checked={ checkAll } style={{ 'fontSize': '16px' }}>全选</Checkbox>
            <div className={ `${ styles['entity-select-tips'] }` }>已选点位 <span className={ `${ styles['important'] }` }>{ selectedPointCount || 0 }</span> 个，覆盖场所 <span className={ `${ styles['important'] }` }>{ selectedEntityCount || 0 }</span> 个</div>
          </div>

          <div className={ `${ styles['btn-confirm'] } ${ selectedPointCount <= 0 || isSubmiting ? styles['is-disabled'] : '' }` } onClick={ onDel }>删除</div>
        </div>
      )
    }

    {/*查看投放日期*/}
    <PointPriceDetailModal  childRef={ pointPriceDetailModalRef } />
    </>
  )
}