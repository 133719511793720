import React, { useState, useEffect, useRef } from 'react'
import { Pagination, Spin, Modal, message } from 'antd'
import { useLocation, useHistory, Link } from 'react-router-dom'

import { NoRecord, GuideModal } from '../../../../components'

import util from '../../../../libs/util'
import { operatorTypeItems, deliveryCinemaModelItems, approveStatusList } from '../../../../libs/util.constants'

import styles from './AdList.module.css'

import { Review } from '../components/review-modal'

interface Props {
  location: {
    search?: any,
  },
}

export const AdList: React.FC<Props> = (props) => {
	const tabList = [
		{
			id: 'APPROVE_WAIT,DELIVERY_WAIT,EXECUTING,FINISHED,NO,CANCEL,CONTENT_TIMEOUT,TIMEOUT_P',
			name: '全部',
		},
		{
			id: 'APPROVE_WAIT',
			name: '待接单',
		},
		{
			id: 'DELIVERY_WAIT',
			name: '待上刊',
		},
		{
			id: 'EXECUTING',
			name: '刊播中',
		},
		{
			id: 'FINISHED',
			name: '已完成',
		},
	]

	useEffect(() => {
		const search = props.location.search
		if (!search) {
			getList(1)
			return
		}
		const status = search?.split('?')[1].split('=')[1]
		const id = tabList.find(i => i.id === status)?.id
		setCurTabId(id)
		getList(1, id)
	}, [])

	const [curTabId, setCurTabId] = useState<any>(tabList[0].id)

	const baseURL = `https://${ util.tools.env }cdn.remudooh.com/`

	const [list, setList] = useState<any[]>([])
  const [defaultCurrent, setDefaultCurrentTotal] = useState(1)
  const [pageNum, setPageNum] = useState(1)
  const [pageSizeOptions, setPageSizeOptions] = useState(['10', '20', '30'])
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  useEffect(() => {
    setPageNum(defaultCurrent)
  }, [pageSize])

  const [isSubmiting, setIsSubmiting] = useState(false)
	// 获取列表
  const getList = async (num?: number, id?: string) => {
    if (num) {
      setPageNum(num)
    }
    setIsSubmiting(true)
    const { data = [], total = 0, code } = await util.request({
      method: 'POST',
      url: `/mall/media/order/searchOrderMediaList?pageNum=${num || pageNum}&pageSize=${pageSize}`,
      data: {
	      orderByCreateDate: 'DESC',
	      planAims: ['NORMAL', 'KA'],
	      approveStatus: (id || curTabId).split(','),
	      pageNum,
	      pageSize,
	    },
    })
    if (code === 1) {
	    setList(data)
	    setTotal(total)
	    setIsSubmiting(false)
	  }
  }
  // 页码变化
  const onPageChange = (page, size) => {
    if (pageSize !== size) {
      return
    }
    setPageNum(page)
    getList(page)
  }
  const onShowSizeChange = (current, size) => {
    setPageSize(size)
  }

  const toggleTab = (id) => {
  	if (id === curTabId) {
  		return
  	}
  	setCurTabId(id)
  	setPageNum(1)
  	getList(1, id)
  	console.log('toggleTab', id, curTabId)
  }

  const history = useHistory()
  const goToDetailPage = (data) => {
  	history.push(`/remo-ad/detail/${ data.mallOrderMediaId }`)
  }

  const [servicePhoneNumber, setServicePhoneNumber] = useState()
  // 查客服电话
  const getServicePhoneNumber = async() => {
  	const { data, code } = await util.request({
      method: 'GET',
      url: `/config/biz/getBizConfig`,
      params: {
      	itemKey: 'SERVICE_PHONE'
      },
    })
    if (code === 1) {
    	setServicePhoneNumber(data.itemValue)
    } 
  }

  useEffect(() => {
  	getServicePhoneNumber()
  }, [])

  const reviewModalRef: any = useRef()
  const showReviewModal = (ad) => {
    // reviewModalRef && reviewModalRef.current.showModal(ad)
    reviewModalRef.current.onToggle()
  }

  const onReport = (ad) => {
  	history.push(`/remo-ad/report/home/${ ad.mallOrderMediaId }`)
  }

	const onRemove = (record) => {
		Modal.confirm({
			title: '确认要删除订单？',
			cancelText: '取消',
			okText: '确定',
			onOk() {
				goToRemove(record.mallOrderMediaId)
			},
			onCancel() {
			},
		})
	}
	// 去删除
  const goToRemove = async (id) => {
		setIsSubmiting(true)
		const { data = [], message: msg, code } = await util.request({
      method: 'DELETE',
      url: `/mall/media/order/removeOrderMedia/${ id }`,
    })

  	if (code === 1) {
      message.success('删除成功')
			getList(1)
    } else {
      message.error(msg || '操作失败')
    }
    setIsSubmiting(false)
  }

  return (
    <div className={ `${ styles['ad-page'] }` }>
      <div className={ `${ styles['ad-page-tit'] }` }>
        <h3>热幕订单</h3>
      </div>

      <div className={ `${ styles['ad-cont'] }` }>
      	<div className={ `${ styles['ad-cont-tit'] }` }>热幕订单</div>

      	<div className={ `${ styles['ad-list-top'] }` }>
	      	{/*tab*/}
	      	<div className={ `${ styles['tab-list'] }` }>
		      	{
		      		tabList.map((tab) => {
		      			return (
		      				<div className={ `${ styles['tab-item'] } ${ curTabId === tab.id ? styles['is-selected'] : '' }` } key={ tab.id } onClick={ () => { toggleTab(tab.id) } }>{ tab.name }</div>
		      			)
		      		})
		      	}
	      	</div>
      	</div>

      	{/*list*/}
      	<Spin spinning={ isSubmiting } tip="请稍候...">
      		{
      			(list.length > 0) && (
      				<div className={ `${ styles['ad-list'] }` }>
	      				{
	      					list.map((ad) => {
	      						return (
	      							<div className={ `${ styles['ad-item'] }` } key={ ad.mallOrderPlanId }>
	      								<div className={ `${ styles['ad-labels'] }` }>
	      									<div className={ `${ styles['ad-label'] }` }>{ util.tools.getNameFromKey(operatorTypeItems, ad.operatorType) }
	      									</div>
	      									<div className={ `${ styles['ad-label'] }` }>{ util.tools.getNameFromKey(deliveryCinemaModelItems, ad.deliveryCinemaModel) }</div>
	      								</div>

	      								<div className={ `${ styles['ad-status'] }` }>{ approveStatusList.find(i => i.itemKey.split(',').includes(ad.approveStatus))?.itemValue }</div>

	      								
	      								<div className={ `${ styles['ad-item-cont'] }` }  onClick={ () => { goToDetailPage(ad) } }>
	      									{/*左边缩略图*/}
	      									{/*不显示图片*/}
	      									{
	      										(ad.approveStatus === 'CONTENT_TIMEOUT' || ad.approveStatus === 'TIMEOUT_P' || !ad.thumbnailUrl || !ad.thumbnailUrl.length) && (
	      											<div className={ `${ styles['ad-material'] }` }>
	      												<div className={ `icon ${ styles['ad-material-default'] }` }></div>
	      												{/*未提交素材*/}
	      												{
	      													(ad.approveStatus === 'CONTENT_TIMEOUT' || ad.approveStatus === 'TIMEOUT_P') && (
	      														<div className={ `${ styles['ad-material-tips'] }` }>广告主未<br/>提供广告内容</div>
	      													)
	      												}

	      												{/*不符合投放要求*/}
	      												{
	      													((ad.approveStatus !== 'CONTENT_TIMEOUT' && ad.approveStatus !== 'TIMEOUT_P') && (!ad.thumbnailUrl || !ad.thumbnailUrl.length)) && (
	      														<div className={ `${ styles['ad-material-tips'] }` }>广告主暂未<br/>提供广告内容</div>
	      													)
	      												}
	      											</div>
	      										)
	      									}

	      									{
														(ad.approveStatus !== 'CONTENT_TIMEOUT' && (ad.thumbnailUrl && ad.thumbnailUrl.length)) && (<div className={ `${ styles['ad-material'] }` }><img className={ `${ styles['ad-material-image'] }` } src={ `${ baseURL }${ ad.thumbnailUrl[0] }?imageMogr2/thumbnail/x152` } /></div>)
													}


	      									{/* 右边信息 */}
													<div className={ `${ styles['ad-item-r'] }` }>
			      								<div className={ `${ styles['delivery-name'] }` }>{ ad.mediaDeliveryName }</div>

			      								{/*订单详细数据*/}
														<div className={ `${ styles['ad-form'] }` }>
															<div className={ `${ styles['ad-form-item'] }` }>
																<div className={ `${ styles['ad-form-label'] }` }>
																	<div className={ `${ styles['ad-form-icon'] } icon icon-calendar` }></div>
																	投放周期：
																</div>
																<div className={ `${ styles['ad-form-value'] }` }>
																	{ util.tools.formatDateStr(ad.mediaDeliveryStart) }-{ util.tools.formatDateStr(ad.mediaDeliveryEnd) }<span className={ `${ styles['text-days'] }` }>({ ad.deliveryDays }天)</span>
																</div>
															</div>
															<div className={ `${ styles['ad-form-item'] }` }>
																<div className={ `${ styles['ad-form-label'] }` }>
																	<div className={ `${ styles['ad-form-icon'] } icon icon-clock` }></div>
																	广告时长：
																</div>
																<div className={ `${ styles['ad-form-value'] }` }>{ ad.deliveryContentDuration }秒
																	{
																		(ad.planAim === 'EXCLUSIVE_AD' || ad.planAim === 'EXCLUSIVE_MEDIA' || ad.planAim === 'SELF') && '以内'
																	}
																</div>
															</div>
															<div className={ `${ styles['ad-form-item'] }` }>
																<div className={ `${ styles['ad-form-label'] }` }>
																	<div className={ `${ styles['ad-form-icon'] } icon icon-order` }></div>
																	播放时段：
																</div>
																<div className={ `${ styles['ad-form-value'] }` }>
																	{ util.tools.convertTimePeriods(ad)}
																</div>
															</div>
															<div className={ `${ styles['ad-form-item'] }` }>
																<div className={ `${ styles['ad-form-label'] }` }>
																	<div className={ `${ styles['ad-form-icon'] } icon icon-cell` }></div>
																	投放点位：
																</div>
																<div className={ `${ styles['ad-form-value'] }` }>
																	{ (ad.pointCount) || 0 }个
																	{
																		(ad.mediaNoApproveNumber > 0) && <span className={ `${ styles['ad-form-value-tips'] }` }>{ ad.mediaNoApproveNumber }个谢绝投放</span>
																	}
																</div>
															</div>
														</div>
													</div>
												</div>

												{/*右边操作及提醒*/}
												<div className={ `${ styles['ad-item-opts'] } ${ (ad.approveStatus === 'EXECUTING' || ad.approveStatus === 'FINISHED') ? styles['column'] : '' }` }>
													{/*计划关闭提醒*/}
													{
														(ad.approveStatus === 'NO' || ad.approveStatus === 'CONTENT_TIMEOUT' || ad.approveStatus === 'TIMEOUT_P' || ad.approveStatus === 'CANCEL') && (
															<div className={ `${ styles['ad-close-reason'] }` }><span className={ `${ styles['ad-close-icon'] } ifont i-tips` }></span>{ util.tools.orderTimeoutStatus(ad.approveStatus, servicePhoneNumber) }</div>
														)
													} 

													{/*1分钟之内不显示提示*/}
													{
														(ad.approveStatus === 'APPROVE_WAIT' && util.tools.hasLeftTime(ad.approveTimeOut)) && (
															<>
																<div className={ `${ styles['ad-content-tips'] }` }>剩余<span className={ `${ styles['ad-end-date'] }` }>{ util.tools.convertCountDownTime(ad.approveTimeOut) }</span>将自动接单</div>

																<div className={ `${ styles['btn-confirm'] }` } onClick={ () => { showReviewModal(ad) } }>接单确认</div>
															</>
															)
													}

													{/*刊播提醒 */}
													{
														(ad.approveStatus === 'DELIVERY_WAIT' && (!ad.showPlanStatusMore?.includes('CONTENT_NULL') && !ad.showPlanStatusMore?.includes('CONTENT_NOT_MATCH'))) && (
															<div className={ `${ styles['ad-content-tips'] }` }>距离最早刊播日期还有<span className={ `${ styles['ad-delivery-days'] }` }>{ ad.startBetweenDays }</span>天</div>
															)
													}

													{/*价格*/}
													{/*待上刊 刊播中 已完成*/}
													{
														(ad.approveStatus == 'DELIVERY_WAIT' || ad.approveStatus == 'EXECUTING' || ad.approveStatus == 'FINISHED') && (
															<div className={ `${ styles['ad-price'] }` }>
																<div className={ `${ styles['ad-price-label'] }` }>预估最高</div>
																<div className={ `${ styles['ad-price-intro'] }` }>订单收益：</div>
											          <div className={ `${ styles['text-price'] }` }>
											          	<div className={ `${ styles['text-price-unit'] }` }>￥</div>
											          	<div className={ `${ styles['text-price-int'] }` }>{ util.tools.splitNum2StringFixed(ad.realPayAmount) }</div>
											          </div>
															</div>
														)
													}

													{/*查看刊播数据*/}
													{
														(ad.approveStatus === 'EXECUTING' || ad.approveStatus === 'FINISHED') && (
															<div className={ `${ styles['btn-confirm'] }` } onClick={ () => { onReport(ad) } }>刊播数据</div>
														)
													}

													{/*删除*/}
													{
														(ad.approveStatus === 'NO' || ad.approveStatus === 'CANCEL' || ad.approveStatus === 'CONTENT_TIMEOUT' || ad.approveStatus === 'TIMEOUT_P') && (
															<>
																<div className={ `${ styles['btn-confirm'] }` } onClick={ () => { onRemove(ad) } }>删除</div>
															</>
															)
													}

												</div>
	      							</div>
	      						)
	      					})
	      				}
	      			</div>
      			)
      		}
	      	
	      	{
	          // 翻页
	          (list.length > 0) && (
	            <div className={ `${ styles.paging } flex justify-end` }>
	              <Pagination 
	                showQuickJumper 
	                showSizeChanger
	                showTotal={ total => `共 ${total} 条`}
	                defaultCurrent={ defaultCurrent }
	                current={ pageNum }
	                pageSize={ pageSize }
	                pageSizeOptions={ pageSizeOptions }
	                total={ total } 
	                onChange={ onPageChange }
	                onShowSizeChange={ onShowSizeChange }
	                 />
	            </div>
	          )
	        }

	        {
	        	list.length <= 0 && <NoRecord />
	        }
      	</Spin>
      </div>

      {/* 接单 */}
      {/*<Review childRef={ reviewModalRef } />*/}
      <GuideModal childRef={ reviewModalRef } />
    </div>
  )
}