import React, { useState, useContext, useEffect } from 'react'
import { Form, Input, Button, Select, Row, Col } from 'antd'
import util from '../../../../libs/util'
import styles from './FilterPanel.module.css'
import { filterContext, setFilterContext, defaultFilterContextValue } from '../PointList'

// 激活状态
export const pointStatusItems = [
  {
    itemKey: 'NOT_ACTIVATE',
    itemValue: '未激活',
    itemDesc: '否',
  },
  {
    itemKey: 'ACTIVATE',
    itemValue: '已激活',
    itemDesc: '是',
  },
]
// 企业审核状态
export const mediaApproveStatusItems = [
  {
    itemKey: 'WAIT',
    itemValue: '待审核',
    className: 'warn',
  },
  {
    itemKey: 'YES',
    itemValue: '通过',
    className: 'success',
  },
  {
    itemKey: 'NO',
    itemValue: '不通过',
    className: 'error',
  },
]
// 平台审核状态 WAIT待审批 YES同意 NO拒绝
export const approveStatusItems = [
  {
    itemKey: 'WAIT',
    itemValue: '待审核',
    className: 'warn',
  },
  {
    itemKey: 'YES',
    itemValue: '通过',
    className: 'success',
  },
  {
    itemKey: 'NO',
    itemValue: '不通过',
    className: 'error',
  },
]

// 点位状态
export const cinemaPointStatus = [
  {
    itemKey: 'NOT_RELEASE',
    itemValue: '待入驻',
    className: 'warn',
  },
  {
    itemKey: 'RELEASE',
    itemValue: '已入驻',
    className: 'success',
  },
]

export const normalPointStatus = [
  {
    itemKey: 'NOT_ACTIVATE',
    itemValue: '待激活',
    className: 'gray',
  },
  {
    itemKey: 'ACTIVATE',
    itemValue: '待入驻',
    className: 'warn',
  },
  {
    itemKey: 'RELEASE',
    itemValue: '已上架',
    className: 'success',
  },
  {
    itemKey: 'OWN',
    itemValue: '已发布自用',
    className: 'primary',
  },
]

const baseEntityList = [{
  resourceEntityInfoId: '',
  entityName: '全部',
}]

interface Props {
  onSearch?: any,
}

export const FilterPanel: React.FC<Props> = (props) => {
  const [pointFilter] = Form.useForm();

  const filter = useContext(filterContext)
  const setFilter = useContext(setFilterContext)

  const [cityList, setCityList] = useState<any[]>([])
  const [districtList, setDistrictList] = useState<any[]>([])

  useEffect(() => {
    getOrgEntityDistrictGroup()
  }, [])

  const [showPointStatusItems, setShowPointStatusItems] = useState<any[]>([])
  useEffect(() => {
    setShowPointStatusItems(filter.operatorType === 'CINEMA' ? cinemaPointStatus : normalPointStatus)
  }, [filter.operatorType])

  const [isFold, setIsFold] = useState(true)
  // TODO
  // 折叠/收起
  const toggleEntity = () => {
    setIsFold(!isFold)
  }

  const inputStyle = {
    borderRadius: '4px', 
    borderColor: '#E6E6E6'
  }
  const onValuesChange = (values, allValues) => {
    setFilter(state => {
      return {
        ...state,
        ...allValues,
      }
    })
  }
  const onSearch = () => {
    props.onSearch()
  }
  const onReset = () => {
    setFilter(state => {
      return {
        ...state,
        ...defaultFilterContextValue,
      }
    })
    pointFilter.resetFields()
    setDistrictList([])
  }

  // 获取城市，地区
  const getOrgEntityDistrictGroup = async () => {
    const { code, data = [] } = await util.request({
      method: 'POST',
      url: '/resource/entity/getOrgEntityDistrictGroup',
      data: {
        mediaResourceType: 'CINEMA_SCREEN',
      },
    })

    if (code === 1) {
      setCityList(data)
    }
  }

  // 选择城市
  const onCityChange = (city) => {
    setFilter(state => {
      return {
        ...state,
        city,
        district: '',
      }
    })

    const districtList = city ? cityList.find(i => i.city === city).districtList : []
    setDistrictList(districtList)
  }
  // 选择区
  const onDistrictChange = (district) => {
    setFilter(state => {
      return {
        ...state,
        district,
      }
    })
  }
  // 选择点位状态
  const onReleaseStatusChange = (releaseStatus) => {
    setFilter(state => {
      return {
        ...state,
        releaseStatus,
      }
    })
  }
  // 选择激活状态
  const onPointStatusChange = (pointStatus) => {
    setFilter(state => {
      return {
        ...state,
        pointStatus,
      }
    })
  }
  // 企业审核状态
  const onMediaApproveStatusChange = (mediaApproveStatus) => {
    setFilter(state => {
      return {
        ...state,
        mediaApproveStatus,
      }
    })
  }
  // 平台审核状态
  const onApproveStatusChange = (approveStatus) => {
    setFilter(state => {
      return {
        ...state,
        approveStatus,
      }
    })
  }

  return (
    <div className={ styles['filter-panel'] }>
      <Form 
        form={ pointFilter }
        name="pointFilter" 
        onValuesChange={ onValuesChange }
        onFinish={ onSearch }
        autoComplete="off"
        size="large"
        >
        <Row gutter={ 24 }>
          <Col span={ 6 }>
            <Form.Item
              label="点位编号"
              name="pointNumber"
              >
              <Input 
                value={ filter.pointNumber }
                style={ inputStyle }
                placeholder="请输入" 
              />
            </Form.Item>
          </Col>
          <Col span={ 6 }>
            <Form.Item 
              label="城市"
              name="city"
              >
              <Select
                style={ inputStyle }
                allowClear
                onChange={ onCityChange }
                >
                {
                  cityList.map((record, index) => {
                    return (
                      <Select.Option key={ record.city } value={ record.city }>{ record.cityName }</Select.Option>
                    )
                  })
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={ 6 }>
            <Form.Item 
              label="区"
              name="district"
              >
              <Select
                style={ inputStyle }
                allowClear
                onChange={ onDistrictChange }
                >
                {
                  districtList.map((record, index) => {
                    return (
                      <Select.Option key={ record.district } value={ record.district }>{ record.districtName }</Select.Option>
                    )
                  })
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={ 6 }>
            <Form.Item
              label="场所"
              name="entityName"
              >
              <Input 
                style={ inputStyle }
                placeholder="请输入" 
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={ 24 }>
          <Col span={ 6 }>
            <Form.Item
              label="点位名称"
              name="pointName"
              >
              <Input 
                style={ inputStyle }
                placeholder="请输入" 
              />
            </Form.Item>
          </Col>
          <Col span={ 6 }>
            <Form.Item 
              label="点位状态"
              name="releaseStatus"
              >
              <Select
                style={ inputStyle }
                allowClear
                onChange={ onReleaseStatusChange }
                >
                {
                  showPointStatusItems.map((record, index) => {
                    return (
                      <Select.Option key={ record.itemKey } value={ record.itemKey }>{ record.itemValue }</Select.Option>
                    )
                  })
                }
                
              </Select>
            </Form.Item>
          </Col>
          <Col span={ 6 }>
            <Form.Item 
              label="企业审核状态"
              name="mediaApproveStatus"
              >
              <Select
                style={ inputStyle }
                allowClear
                onChange={ onMediaApproveStatusChange }
                >
                {
                  mediaApproveStatusItems.map((record, index) => {
                    return (
                      <Select.Option key={ record.itemKey } value={ record.itemKey }>{ record.itemValue }</Select.Option>
                    )
                  })
                }
                
              </Select>
            </Form.Item>
          </Col>
          {/* <Col span={ 6 }>
            <Form.Item 
              label="平台审核状态"
              name="approveStatus"
              >
              <Select
                style={ inputStyle }
                allowClear
                onChange={ onApproveStatusChange }
                >
                {
                  approveStatusItems.map((record, index) => {
                    return (
                      <Select.Option key={ record.itemKey } value={ record.itemKey }>{ record.itemValue }</Select.Option>
                    )
                  })
                }
                
              </Select>
            </Form.Item>
          </Col> */}
          <Col span={ 6 }>
            <Form.Item 
              label="是否已激活"
              name="pointStatus"
              >
              <Select
                style={ inputStyle }
                allowClear
                onChange={ onPointStatusChange }
                >
                {
                  pointStatusItems.map((record, index) => {
                    return (
                      <Select.Option key={ record.itemKey } value={ record.itemKey }>{ record.itemValue }</Select.Option>
                    )
                  })
                }
                
              </Select>
            </Form.Item>
          </Col>
          <Col span={ 6 }>
            <Form.Item>
              <Button 
                style={{
                  marginRight: '20px',
                  backgroundColor: '#E2A45A',
                  borderColor: '#E2A45A',
                  borderRadius: '4px',
                }}
                type="primary" 
                htmlType="submit">
                查询
              </Button>
              <Button 
                htmlType="button" 
                style={{
                  borderColor: '#EDEDED',
                  borderRadius: '4px',
                }}
                onClick={ onReset }>
                重置
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}