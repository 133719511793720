import React, { useState, useContext, useEffect, useImperativeHandle, useRef } from 'react'
import { Modal, Calendar, } from 'antd'
import moment from 'moment'
import util from '../../../../../libs/util'
import { PointGradeIntroModal } from '../point-grade-intro-modal'
import { PriceRulesIntroModal } from '../price-rules-intro-modal'


import styles from './PriceModal.module.css'

const dateFormat = 'YYYY-MM-DD'

interface Props {
  childRef: any,
}

export const PriceModal: React.FC<Props> = ({ childRef }) => {
	useImperativeHandle(childRef, () => ({
    showModal: (record) => {
      showModal()
      console.log('showModal', record)
      getPointDetail(record)
    }
  }))

  const [currentDate, setCurrentDate] = useState('')
  const onPanelChange = (date, mode) => {
    if (mode === 'month') {
      const selectedDateStr = util.moment(date).format('YYYY-MM-DD')
      const selectedDate = new Date(selectedDateStr)
      const year = selectedDate.getFullYear()
      const month = selectedDate.getMonth()
      setCurrentDate(util.tools.formatDate(new Date(year, month, 1)))
    }
  }

  // 点位信息
  const [point, setPoint] = useState<any>()
  const [priceList, setPriceList] = useState<any>()
  const getPointDetail = async({ resourcePointId, mallOrderPlanId, mallOrderMediaId, deliveryDays }) => {
    const { code, data } = await util.request({
      method: 'GET',
      url: `/mall/delivery/getOrderPointDetail`,
      params: {
        resourcePointId,
        mallOrderPlanId,
        mallOrderMediaId,
      },
    })

    if (code === 1) {
      setPoint(Object.assign({}, data, { resourcePointId, mallOrderPlanId, mallOrderMediaId, deliveryDays }))
      setPriceList(data.priceList)
      const currentDate = util.tools.formatDate(new Date(util.tools.formatDateYm(new Date())))
      const start = Math.max(+new Date(currentDate), +new Date(data.mediaDeliveryStart))
      const end = Math.max(+new Date(currentDate), +new Date(data.mediaDeliveryEnd))
      const newCurDate = +new Date(currentDate) < +new Date(data.mediaDeliveryStart) ? data.mediaDeliveryStart : (+new Date(currentDate) > +new Date(data.mediaDeliveryEnd) ? data.mediaDeliveryEnd : currentDate )
      setCurrentDate(newCurDate)
    }
  }

  const dateFullCellRender = (value) => {
    const dateStr = moment(value).format(dateFormat)
    const item = priceList?.find(i => i.pointDate === dateStr)
    const isToday = dateStr === moment().startOf('day').format(dateFormat)
    return (
      <div className={ `${ styles['calendar-cell'] } ${ isToday ? styles['is-today'] : '' }  ${ item ? styles['calendar-delivery-bg'] : '' } ` }>
        <div className={ `${ styles['calendar-date'] }` }>{ moment(value).date() }</div>
        <>
          { item ? <><span className={ `${ styles['price-unit'] }` }>￥</span>{ util.tools.splitNum2StringFixed(item.settlePrice) }</> : '' }
        </>
      </div>
    )
  }

  const convertDate = function(date) {
    return date?.split('-')[0] === '9999' ? '长期' : date?.split('-').join('.')
  }
  // YYYY-MM-DD to YYYY.MM.DD
  const formatDate = (dateStr) => {
    return dateStr ? dateStr.split('-').join('.') : ''
  }

  // 弹窗
  const [isModalVisible, setIsModalVisible] = useState(false)
  const showModal = () => {
    setIsModalVisible(true)
  }
  const onModalCancel = () => {
    setIsModalVisible(false)
    setCurrentDate('')
  }

  const pointGradeIntroModalRef: any = useRef()
  const showPointGradeModal = () => {
    pointGradeIntroModalRef.current.showModal()
  }

  const priceRulesIntroModalRef: any = useRef()
  const showPriceRulesIntroModal = () => {
    priceRulesIntroModalRef.current.showModal()
  }

  return (
  	<Modal 
	    width={ 1104 }
	    title="点位预估收益"
	    visible={ isModalVisible }
	    footer={ null }
      onCancel={ onModalCancel }
	    >
      <div className={ styles['modal-top'] }>
        <div className={ styles['point-name'] }>
          <div className={ styles['point-name-text'] }>{ point?.pointDevice?.pointName }</div>
          <div className={ styles['rate-list'] }>
            {
              new Array(point?.pointDevice?.pointGrade).fill(1).map((item, index) => {
                return <div className={ `${ styles['rate-item'] } imediafont i-fire` } key={ index }></div>
              })
            }
          </div>
          <div className={ `${ styles['icon-tips'] } imediafont i-question-media` } onClick={ showPointGradeModal }></div>
        </div>
        <div className={ styles['entity-name'] }>{ point?.pointDevice?.entityName }</div>
      </div>

      <div className={ `${ styles['ad-form'] }` }>
        <div className={ `${ styles['ad-form-item'] }` }>
          <div className={ `${ styles['ad-form-label'] }` }>
            <div className={ `${ styles['ad-form-icon'] } icon icon-calendar` }></div>
            投放周期：
          </div>
          <div className={ `${ styles['ad-form-value'] }` }>
            { util.tools.formatDateStr(point?.mediaDeliveryStart) }-{ util.tools.formatDateStr(point?.mediaDeliveryEnd) }<span className={ `${ styles['text-days'] }` }>({ point?.deliveryDays }天)</span>
          </div>
        </div>
        <div className={ `${ styles['ad-form-item'] }` }>
          <div className={ `${ styles['ad-form-label'] }` }>
            <div className={ `${ styles['ad-form-icon'] } icon icon-order` }></div>
            播放时段：
          </div>
          <div className={ `${ styles['ad-form-value'] }` }>
            {
              (point) && (<>{ util.tools.convertTimePeriods(point) }</>)
            }
          </div>
        </div>
      </div>

      <div className={ `${ styles['calendar-tips'] }` }>
        <div className={ `${ styles['calendar-tips-item'] }` }>
          <div className={ `${ styles['icon-delivery'] }` }></div>
          投放日期（{ point?.usableDeliveryDays }天）
        </div>
        <div className={ `${ styles['calendar-tips-item'] }` } onClick={ showPriceRulesIntroModal }>
          <div className={ `${ styles['text-important'] }` }>了解结算规则</div>
          <div className={ `${ styles['btn-more'] } imediafont i-arrow-right` }></div>
        </div>
      </div>

      <div>
        <Calendar 
          value={ currentDate ? moment(currentDate) : moment() }
          dateFullCellRender={ dateFullCellRender } 
          onPanelChange={ onPanelChange } />
      </div>

      <div className={ styles['modal-footer'] } ><span className={ styles['modal-footer-label'] } >合计</span> ￥{ point?.settlePriceTotal }</div>

      
      {/*点位评级*/}
      <PointGradeIntroModal  childRef={ pointGradeIntroModalRef } />
      {/*结算规则*/}
      <PriceRulesIntroModal childRef={ priceRulesIntroModalRef } />
	  </Modal>
  )
}